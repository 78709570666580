// Generated by Framer (47124a4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {suje0OAhF: {hover: true}};

const cycleOrder = ["suje0OAhF"];

const serializationHash = "framer-A8oq0"

const variantClassNames = {suje0OAhF: "framer-v-5oitzy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 300, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, k27kxwZ_T: title ?? props.k27kxwZ_T ?? "Testimonials", pYRXtgGOT: link ?? props.pYRXtgGOT} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, k27kxwZ_T, pYRXtgGOT, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "suje0OAhF", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-A8oq0", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={pYRXtgGOT}><motion.a {...restProps} className={`${cx("framer-5oitzy", className)} framer-1aghidl`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"suje0OAhF"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"suje0OAhF-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7RXhvIDItNTAw", "--framer-font-family": "\"Exo 2\", \"Exo 2 Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-line-height": "120%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Testimonials</motion.p></React.Fragment>} className={"framer-1em6ygg"} fonts={["GF;Exo 2-500"]} layoutDependency={layoutDependency} layoutId={"vuhUVDowI"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={k27kxwZ_T} variants={{"suje0OAhF-hover": {"--extracted-r6o4lv": "rgb(24, 165, 231)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"suje0OAhF-hover": {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7RXhvIDItNTAw", "--framer-font-family": "\"Exo 2\", \"Exo 2 Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-line-height": "120%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(24, 165, 231))"}}>Testimonials</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-A8oq0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-A8oq0 .framer-1aghidl { display: block; }", ".framer-A8oq0 .framer-5oitzy { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-A8oq0 .framer-1em6ygg { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-A8oq0 .framer-5oitzy { gap: 0px; } .framer-A8oq0 .framer-5oitzy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-A8oq0 .framer-5oitzy > :first-child { margin-left: 0px; } .framer-A8oq0 .framer-5oitzy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 116
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"lLN1WKakg":{"layout":["auto","auto"]}}}
 * @framerVariables {"k27kxwZ_T":"title","pYRXtgGOT":"link"}
 * @framerImmutableVariables true
 */
const Framerfxz0DUjSN: React.ComponentType<Props> = withCSS(Component, css, "framer-A8oq0") as typeof Component;
export default Framerfxz0DUjSN;

Framerfxz0DUjSN.displayName = "Nav Link";

Framerfxz0DUjSN.defaultProps = {height: 24, width: 116};

addPropertyControls(Framerfxz0DUjSN, {k27kxwZ_T: {defaultValue: "Testimonials", displayTextArea: false, placeholder: "About", title: "Title", type: ControlType.String}, pYRXtgGOT: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerfxz0DUjSN, [{family: "Exo 2", style: "normal", url: "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jjPKcO9q-rpvLpQ.woff2", weight: "500"}])